import React from 'react'
import '../style.css';
/**
* @author
* @function Testimonial
**/

const Testimonial = (props) => {
  return(
    <div class="col">
    <div class="testimonial">
        <img src={props.data.img} alt="" />
        <div class="name">{props.data.name}</div>
        <p>
            {props.data.desc}
        </p>
    </div>
    </div>

   )

 }

export default Testimonial;