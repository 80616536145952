const axios = require('axios');
const token= process.env.REACT_APP_API_TOKEN;
const baseURL='https://naitik.pythonanywhere.com/api/';

const instance = axios.create({
    baseURL:baseURL,
    headers:{
        Authorization: 'Token '+token,
    }

});

const careerInstance = axios.create({
    baseURL:baseURL,
    headers: {
        Authorization: 'Token '+token,
        
      }

});

export async function execGetApi(endpoint){
    return await instance.get(endpoint);
}




export async function execApi(endpoint, data = {}) {
    return new Promise((resolve, reject) => {

        return instance.post(endpoint, data)
            .then(response => {
                return response.data;
            }).then(rData => {
                if (rData.success == false) {
                    return resolve({ error: new Error(rData.message || 'Api Error') });
                } else {
                    return resolve({ error: false, data: rData });
                }
            }).catch(error => {
                let message = error.message;
                if (error.response && error.response.data) {
                    message = error.response.data.message || error.response.statusText;
                }
                return resolve({ error: new Error(message) });
            })
    });
}

export async function execCareerApi(endpoint, data) {
    return new Promise((resolve, reject) => {

        return careerInstance.post(endpoint, data)
            .then(response => {
                return response.data;
            }).then(rData => {
                if (rData.success == false) {
                    return resolve({ error: new Error(rData.message || 'Api Error') });
                } else {
                    return resolve({ error: false, data: rData });
                }
            }).catch(error => {
                let message = error.message;
                if (error.response && error.response.data) {
                    message = error.response.data.message || error.response.statusText;
                }
                return resolve({ error: new Error(message) });
            })
    });
}
