import { Box, Container, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Footer from '../../../components/FooterContainer/commonFooter';
import Img1 from './img1.svg';
import Profile from './profile.svg';
import Insta from './insta.svg';
import FB from './fb.svg';
import LinkedIn from './linkedin.svg';
import CopyLink from './copyLink.svg';
import ArticleImg from './article.svg';
import TimerIcon from '@material-ui/icons/Timer';
import './style.css';
import parse from 'html-react-parser';
import { execGetApi } from '../../../lib/apiProvider';
import { useParams } from 'react-router';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Sticky from 'react-sticky-el';
import { useHistory } from 'react-router';

const DetailedBlog = () => {
  const history = useHistory();
  let { id } = useParams();
  const [blogDetail, setBlogDetail] = useState();
  useEffect(() => {
    async function fetchData() {
      const request = execGetApi(`blogDetail/${id}`);
      return request;
    }
    const req = fetchData();
    req.then(r => {
      setBlogDetail(r.data);
    })
  }, []);

  // console.log(blogDetail);

  const relatedBlogs = () => {
    if(blogDetail){
      if(blogDetail.related_blogs.length >= 0){
        return (
          <>
        {blogDetail.related_blogs.map((b,i) => {
          return(
            <>
                <a  style={{cursor:'pointer'}} onClick={()=>window.location.assign(`${window.location.origin}/blog/get-details/${b.id}`)}>
                <Grid container direction="row" spacing={3} justify="center">
                  <Grid item lg={5} md={6}>
                    <img src={b.src} style={{ width: "100%",height:'100%',borderRadius:'15px' }} />
                  </Grid>
                  <Grid item lg={7} md={6}>
                    <Typography variant="caption" style={{ fontSize: "13px",display: 'flex',marginTop:'10px' }}><TimerIcon />&nbsp;{b.time} min read</Typography>
                    <Typography style={{ fontSize: "18px", fontWeight: '700' }}>{b.title}</Typography>
                    <Typography variant="body2">{b.desc}</Typography>
                  </Grid>
                </Grid>
                </a>
          </>
          );
        })}
        </>
        );
        
        
      
    }
    else{
      return(
        <h4>No related Article</h4>
      );
      
    }
    }
      
  }
  return (
    <div style={{
      display: 'flex',
      color: '#000',
      background: '#FFF',
      flexDirection: 'column',
      position: 'relative',
      overflowX: 'hidden',
      backgroundColor: '#FFFF'
    }} >

      <Container >
        <Grid container justify="center" className="container-height" spacing={3}>
          <Grid item lg={10} md={10}>
            <Typography variant="h4" style={{ fontWeight: '800' }}>{blogDetail? blogDetail.blog.title:''}</Typography>
          </Grid>
          <Grid item lg={10} style={{ position: "relative", width: '100%', height: '450px', borderRadius: '15px', backgroundPosition: 'center', backgroundSize: 'cover', backgroundColor:'#f3f3f3' ,backgroundImage: `url(${blogDetail? blogDetail.blog.cover:''})` }} >
            {/* <img src={Img1} style={{ position: "relative", width: '100%', backgroundPosition: 'center', backgroundSize: 'cover' }} /> */}
          </Grid>
          <Grid item container lg={10} md={10} direction="row" justify="center">
            
            <Grid item lg={4} md={4}>
            <Sticky>

              <Box style={{ display: 'flex', textAlign: 'center', flexDirection: "column" }}>

                <Box style={{ display: 'flex', marginTop:'5em',textAlign: 'center', flexDirection: "row", alignItems: "center" }}>
                  <Box style={{ display: 'flex', justifyContent: "center" }}>
                    <img src={blogDetail ?blogDetail.author.picture:''} style={{ width: "80px",height:'80px',backgroundColor:'#cdcdcd',borderRadius:'100%' }} />
                  </Box>
                  <Box p={2}>
                    <Typography style={{ fontWeight: '800', fontSize: '18px', color: '#676767' }}>Article by</Typography>
                    <Typography style={{ fontWeight: '700', fontSize: '18px' }}>{blogDetail ?blogDetail.author.name:''}</Typography>
                  </Box>
                </Box>

                <br />
                <Typography variant="subtitle1">Share Article</Typography>
                <Box style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                  {/* <img src={LinkedIn} style={{ padding: "0.2em" }} /> */}
                  <a  href={blogDetail ?blogDetail.author.linkedin:''} target="_blank" rel="noopener noreferrer"><LinkedInIcon fontSize='large' style={{color:'#2435C7',margin:'0.2em',cursor:"pointer"}} /></a>
                  {/* <img src={FB} style={{ padding: "0.2em" }} /> */}
                  <a  href={blogDetail ?blogDetail.author.facebook:''} target="_blank" rel="noopener noreferrer"><FacebookIcon fontSize='large' style={{color:'#4C68FB',margin:'0.2em',cursor:"pointer"}} /></a>
                  {/* <img src={Insta} style={{ padding: "0.2em" }} /> */}
                  <a  href={blogDetail ?blogDetail.author.instagram:''} target="_blank" rel="noopener noreferrer"><InstagramIcon fontSize='large' style={{color:'#E2399E',margin:'0.2em',cursor:"pointer"}} /></a>
                </Box>
                <Box style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }} >
                   <img src={CopyLink} style={{ padding: "0.2em",cursor:'pointer'}} onClick={()=> {navigator.clipboard.writeText(window.location.href)}} />
                   
                </Box>
              </Box>
              </Sticky>

              
            </Grid>
            
            <Grid item lg={7} md={7}>
              <Typography variant="body1" className="bodyBlog">
                {parse(blogDetail? blogDetail.blog.blog_content:'')}
              </Typography>
              <br />
              <br />
              <br />
              <Typography style={{ fontWeight: '600', fontSize: '25px' }}>More Articles to read</Typography>
                <br />
                <br />
                <br />
              {relatedBlogs()}

            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Footer />


    </div >
  )
}
export default DetailedBlog;