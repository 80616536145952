import { Box, Chip, Container, FormControl, FormHelperText, FormLabel, Grid, Input, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import './style.css';
import ReCAPTCHA from "react-google-recaptcha";
import HrefLogo from '../../images/hrefIcon.svg'
import { execCareerApi } from '../../lib/apiProvider';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Tick from '../../images/successGif.gif';
import Error from '../../images/errorGIF.gif';
/**
* @author
* @function ContactUs
**/
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        maxWidth: '25em'
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const Positions = ['Frontend Developer', 'Backend Developer', 'UI/UX Designer', 'Graphic Designer', 'Digital Marketing', 'Business Development',];

const CareerRegister = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [PositionsName, setPositionsName] = React.useState([]);
    const [selectedQb, setselectedQb] = React.useState([]);
    const [isStudent, setisStudent] = React.useState("");
    const handleOpen = () => {
        setOpen(true);
    };
    function getStyles(name, PositionsName) {
        return {
            fontWeight:
                PositionsName.indexOf(name) === -1
        };
    }
    const handleChanges = (event) => {
        setPositionsName(event.target.value);

    };
    const handleClose = () => {
        setOpen(false);
        setX(<div class="lds-ring"><div></div><div></div><div></div><div></div></div>);
        setCareerData({
            name: '',
            email: '',
            mobile: '',
            student: false,
            college_Name: '',
            passing_Year: '',
            type: '',
            position: [],
            resume: null
        });
    };

    const handleIsStudent = (event) => {
        setisStudent(event.target.value)
        if(event.target.value == "Yes"){
            setCareerData({
                ...careerData,
                student: true,
              });
        }
        else{
            setCareerData({
                ...careerData,
                student: false,
              });
        }
    };

    const [careerData, setCareerData] = useState({
        name: '',
        email: '',
        mobile: '',
        student: false,
        college_Name: '',
        passing_Year: '',
        type: '',
        position: [],
        resume: null
    })

    const [captcha, setCaptcha] = useState(false);

    const sendData = async () => {
        const data = new FormData() ;
        data.append('name',careerData.name);
        data.append('email',careerData.email);
        data.append('phone',careerData.mobile);
        data.append('student',careerData.student);
        data.append('college_Name',careerData.college_Name);
        data.append('passing_Year',careerData.passing_Year);
        data.append('type',careerData.type);
        data.append('position',careerData.position);
        data.append('resume',careerData.resume);
        const res = await execCareerApi('/career/', data);
        return res;
    }

    const onFileChange = (event) => {
        setCareerData({
            ...careerData,
           resume: event.target.files[0],
          });
    };

    const [x, setX] = useState(<div class="lds-ring"><div></div><div></div><div></div><div></div></div>);
    return (
        <>
            <Grid container justify='center' flexDirection='column' alignItems='center' >
                <Grid item lg={12}>
                    <div className="contactusformContainer">
                        <form className="contactusform" method='post' onSubmit={async (event) => {
                            event.preventDefault();
                            if (captcha) {
                                // console.log(contactUsData);
                                console.log("jhvjhvkj");
                                console.log(careerData);
                                handleOpen();
                                const res = sendData();
                                res.then((value)=>{
                                    console.log(value.data.status);
                                    if(value.data.status == 'success'){
                                        setX(<div className={classes.paper}>
                                            <img style={{ width: '50%' }} src={Tick} />
                                            <h2 style={{ textAlign: 'center' }} id="transition-modal-title">Thanks for submitting!</h2>
                                            <p style={{ textAlign: 'center' }} id="transition-modal-description">Your portfolio has been received, we will reach out to you soon!</p>
                                        </div>);
                                    }
                                    else {
                                        setX(<div className={classes.paper}>
                                            <img style={{ width: '50%' }} src={Error} />
                                            <h2 style={{ textAlign: 'center' }} id="transition-modal-title">A Technical Snag!</h2>
                                            <p style={{ textAlign: 'center' }} id="transition-modal-description">Your portfolio has not been submitted, Please reach us through our offical email-id.</p>
                                        </div>);
                                    }
                                });

                            }
                            else {
                                alert('Please Complete Captcha');
                            }
                        }}>
                            <div style={
                                {
                                    fontWeight: '600',
                                    fontSize: '1.5em',
                                    padding: '0.5em',
                                    textAlign: 'center'
                                }}>
                                <label htmlFor="title" >Career Appeal Form</label>
                            </div>
                            <Box p={1}>
                                <div>
                                    <label htmlFor="name">Your name:</label>
                                </div>
                                <div>
                                    <input type="text" name="name" value={careerData.name} onChange={(event) => setCareerData({ ...careerData, name: event.target.value })} id="name" required />
                                </div>
                            </Box>
                            <Box p={1}>
                                <div>
                                    <label htmlFor="email">Email:</label>
                                </div>
                                <div>
                                    <input type="email" name="email" value={careerData.email} onChange={(event) => setCareerData({ ...careerData, email: event.target.value })} id="email" required />
                                </div>
                            </Box>
                            <Box p={1}>
                                <div>
                                    <label htmlFor="mobile">Phone:</label>
                                </div>
                                <div>
                                    <input type="mobile" name="mobile" value={careerData.mobile} maxlength="10" onChange={(event) => setCareerData({ ...careerData, mobile: event.target.value })} id="mobile" required />
                                </div>
                            </Box>
                            <Box p={1}>
                                <div>
                                    <InputLabel style={{ color: '#000' }} htmlFor="student">Are you a Student?</InputLabel>
                                </div>
                                <div>

                                    <Select
                                        required
                                        native
                                        value={isStudent}
                                        onChange={handleIsStudent}
                                        style={{ width: '100%' }}
                                        inputProps={{
                                            name: 'student',
                                            id: 'student',
                                        }}
                                    >
                                        <option aria-label="None" value="" >Select the Option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>

                                    </Select>

                                </div>
                            </Box>
                            {careerData.student ? <Box p={1}>
                                <div>
                                    <label htmlFor="college">College Name:</label>
                                </div>
                                <div>
                                    <input type="college" name="college" value={careerData.college_Name} onChange={(event) => setCareerData({ ...careerData, college_Name: event.target.value })} id="college" disabled={!careerData.student} required/>
                                </div>
                            </Box> : <div></div>}
                            {careerData.student ? <Box p={1}>
                                <div>
                                    <label htmlFor="passingYear">Passing Year:</label>
                                </div>
                                <div>
                                    <input type="passingYear" name="passingYear" value={careerData.passing_Year} maxlength="4" onChange={(event) => setCareerData({ ...careerData, passing_Year: event.target.value })} id="passingYear" disabled={!careerData.student} required />
                                </div>
                            </Box> : <div></div>}
                            <Box p={1}>
                                <div>
                                    <InputLabel style={{ color: '#000' }} htmlFor="type">Type:</InputLabel>
                                </div>
                                <div>

                                    <Select
                                        required
                                        native
                                        value={careerData.type}
                                        onChange={(event) => setCareerData({ ...careerData, type: event.target.value })}
                                        style={{ width: '100%' }}
                                        inputProps={{
                                            name: 'type',
                                            id: 'type',
                                        }}
                                    >
                                        <option aria-label="None" value="">Select the Type</option>
                                        <option value="Part time">Part Time Intern</option>
                                        <option value="Full time">Full Time Intern</option>
                                        <option value="Job">Job</option>

                                    </Select>

                                </div>
                            </Box>
                            <Box p={1}>
                                <div>
                                    <label htmlFor="position">Position:</label>
                                </div>
                                <div>

                                    <Select
                                        required
                                        style={{ width: '100%' }}
                                        labelId="demo-mutiple-chip-label"
                                        id="demo-mutiple-chip"
                                        multiple
                                        value={careerData.position}
                                        onChange={(event) => setCareerData({ ...careerData, position: event.target.value })}
                                        input={<Input id="select-multiple-chip" />}
                                        renderValue={(selected) => (
                                            <div >
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} style={{ margin: 2 }} />
                                                ))}
                                            </div>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {Positions.map((Position) => (
                                            <MenuItem key={Position} value={Position} style={getStyles(Position, careerData.position)}>
                                                {Position}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </div>
                            </Box>
                            <Box p={1}>
                                <div>
                                    <label htmlFor="resume">Resume Upload</label>
                                </div>
                                <div>
                                    <input type="file" id="myFile" onChange={onFileChange} name="filename" required />
                                </div>
                            </Box>
                            <Box p={1}>
                                <ReCAPTCHA
                                    sitekey="6Ld-FqcaAAAAAJvI2nX2dPxPg8iHrNf7f98B6oAv"
                                    onChange={() => {
                                        setCaptcha(true);
                                    }}
                                />
                                <button type='submit' style={{ cursor: 'pointer' }} value='submit'>Submit</button>
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    {x}
                </Fade>
            </Modal>
        </>
    )

}

export default CareerRegister;