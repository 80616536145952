import { Box, Container, Grid, Typography } from '@material-ui/core';
import React from 'react'
import './style.css';
import ContactForm from '../../components/ContactUs/index';
import Footer from '../../components/FooterContainer/commonFooter';
/**
* @author
* @function ContactUs
**/

const ContactUs = (props) => {
    return (
        <div style={{
            display: 'flex',
            color: '#000',
            background: 'transparent',
            flexDirection: 'column',
            position: 'relative',
            overflowX: 'hidden',
            backgroundColor: '#FFFF'

        }} className="contactUsContainer">
            <div style={{ zIndex: '12', marginTop: '3.6em', background: '#0087CD' }}>
                <Container alignItems='center' style={{ color: '#ffff', justifyContent: 'center', marginTop: '2em', padding: '1em 0em 1em 0em' }}>
                    <Grid container alignItems='center' justify='center' direction='row'>
                        <Grid item lg={6} md={12} >
                            <Box p={2}>
                            <Box p={1}>
                                <Typography variant='h3'>Let’s have a chat!</Typography>
                            </Box>
                            <Box p={1}>
                                <Typography variant='p'>Doubts, Questions or Enquiries we are there for you. Our team member will reach out to you in less than 24 hrs and will assist you at the best. Thanks for choosing Unisight Technologies. </Typography>
                            </Box>
                            </Box>
                        </Grid>


                        <Grid style={{ marginTop: '1em' }} item lg={6} md={12}>
                            <Box p={1}>
                                <ContactForm />
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
                <Footer/>
            </div>

        </div>
    )

}

export default ContactUs;