import React, { useState } from 'react'
import './style.css';
import Slide from "react-reveal/Slide";
import Typical from 'react-typical'
import { useHistory } from 'react-router';
import { Typography } from '@material-ui/core';
/**
* @author
* @function ContentLeft
**/

const ContentLeft = (props) => {
  const [style, changeStyle] = useState({ float: 'left' });
  const history = useHistory();
  var TxtType = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
  };

  TxtType.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(function () {
      that.tick();
    }, delta);
  };

  window.onload = function () {
    var elements = document.getElementsByClassName('typewrite');
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute('data-type');
      var period = elements[i].getAttribute('data-period');
      if (toRotate) {
        new TxtType(elements[i], JSON.parse(toRotate), period);
      }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
    document.body.appendChild(css);
  };
  return (
    <div className="contentLeftContainer">
      <Slide left>
        <div className="contentHeading">
          {/* <div style={{ }}>
            {/* <Typography variant='p'>Connecting solutions with
         
          </Typography> */}
          {/* <Typography variant='p'>
            Connecting solutions with &nbsp;
              <Typography variant='p' class="typewrite" data-period="2000" data-type='[ "Technology.", "Innovation.", "Vision.", "Efficiency." ]'>
                <span class="wrap"></span>
              </Typography>
              </Typography> */}






          <Typography variant='p'>
            Connecting solutions with
            <Typical
              steps={[' Technology.', 1000, ' Innovation.', 1000,' Vision.', 1000,' Efficiency.', 1000]}
              loop={Infinity}
              wrapper="Typography"
            />
          </Typography>
          {/* </div> */}
        </div>
        <button className="buttonGetaQuote" style={style} onClick={() => {
          changeStyle({ float: 'left', background: '#fff', color: '#010606' });
          history.push('/contact');
        }} >Get a quote</button>

      </Slide>
    </div>
  )

}

export default ContentLeft;