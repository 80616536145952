import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import './style.css';
import TimerIcon from '@material-ui/icons/Timer';
import Img from './tempImg.svg';
import { execGetApi } from '../../../lib/apiProvider';
import { useHistory } from 'react-router';

const Filters = ({ onClickAll, all, onClick, filters }) =>

    <form>
        <ul>
            <li onClick={onClickAll}>
                <input
                    type="checkbox"
                    checked={all}
                />
                <label htmlFor="all">All</label>
            </li>
            {filters.map(
                (filter, i) =>
                    <li key={i} data-index={i} onClick={onClick} >
                        <input
                            id={filter.name}
                            type="checkbox"
                            checked={filter.status}
                        />
                        <label style={{whiteSpace:'nowrap'}} htmlFor={filter.name}>{filter.name}</label>
                    </li>)}
        </ul>
    </form>
function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
}
const Cards = ({ imgs }) =>

    <ul>
        {imgs.map(
            (img, i) =>
                <li key={i} style={{ width: '300px', cursor: 'pointer' }} onClick={() => window.location.assign(`blog/get-details/${img.id}`)}>

                    <img src={img.src} />
                    <div style={{ margin: '0 3px' }}>
                        <p style={{ display: 'flex', marginTop: '10px' }}><TimerIcon />&nbsp;{img.time} min read</p>
                        <Typography style={{ fontWeight: '700', fontSize: '18px', minHeight: '3em' }}>{img.title}</Typography>
                        <Typography className="hidden-mobile" variant="subtitle">{truncate(img.desc, 100)}</Typography>
                    </div>

                </li>)}
    </ul>


let imgs = [];
let imgs1 = [];
let imgs2 = [];
let filters=[];
//  if(localStorage.getItem('category') != null) {
//             let xx = JSON.parse(localStorage.getItem('category'));
//             filters = xx.map(x => {
//                 return {
//                     name: x,
//                     status: false
//                 }
        
//             })
//         }
class CategorySearch extends React.Component {
    filters=this.props.category;
    state = {
        imgs,
        imgs1,
        imgs2,
        filters,
        all: true
    };
    
    constructor(props) {
        super(props);
        imgs = this.props.imgs;
          filters=this.props.category;

       

    // filters=this.props.category;

    //  console.log(this.props.category)
}

// componentWillMount() {
//     async function fetchData() {
//         const request = execGetApi('getAllBlogs');
//         return request;
//     }
//     const req = fetchData();
//     req.then(r => {
//          r.data.map(d=>this.state.imgs.push(d));
//         //  this.setState()
//     })
// };

componentDidMount(){
    this.setState({
        all: true,
        filters:this.props.category
    });
}
setFilter = (e) => {
    e.preventDefault();
    
    const { filters, all } = this.state;
    const { index } = e.currentTarget.dataset;

    filters[index].status = !filters[index].status;
    this.setState({
        filters
    });

    this.updateFilters();
    this.updateImgs();
}

setAll = () => {
    const { filters } = this.state;

    filters.forEach(
        filter => {
            filter.status = false;
        }
    );

    this.setState({
        all: true,
        filters
    });
}

updateFilters() {
    const allFiltersTrue = filters.every(filter => filter.status === true);
    const allFiltersFalse = filters.every(filter => filter.status === false);

    if (allFiltersTrue || allFiltersFalse) {
        this.setAll();
    } else {
        this.setState({
            all: false
        });
    }
}

updateImgs() {
    const { filters, all } = this.state;
    let newImgs = [];
    let newImgs1 = [];
    let newImgs2 = [];

    let a = 0;
    let a1 = 0;
    let a2 = 0;

    imgs.forEach((img, imgKey) => {
        filters.forEach((filter, filterKey) => {
            if ((img.tags.includes(filter.name)) && (filter.status == true)) {
                if (!newImgs.includes(img)) {
                    newImgs[a] = img;
                    a++;
                }
            }
        })
    });

    imgs1.forEach((img, imgKey) => {
        filters.forEach((filter, filterKey) => {
            if ((img.tags.includes(filter.name)) && (filter.status == true)) {
                if (!newImgs.includes(img)) {
                    newImgs[a1] = img;
                    a1++;
                }
            }
        })
    });

    imgs2.forEach((img, imgKey) => {
        filters.forEach((filter, filterKey) => {
            if ((img.tags.includes(filter.name)) && (filter.status == true)) {
                if (!newImgs.includes(img)) {
                    newImgs[a2] = img;
                    a2++;
                }
            }
        })
    });

    this.setState({
        imgs: newImgs,
        imgs1: newImgs1,
        imgs2: newImgs2
    });
}

render() {
  
    let { filters, all } = this.state;
   
    
    return (
        <div className='container'>
            {filters.length>0?
            <Filters
                onClickAll={this.setAll}
                all={all}
                onClick={this.setFilter}
                filters={filters} />:''}
            {(all) ? (
                <Grid container direction='column'>
                    <Typography style={{ margin: '0.5em' }} variant='h4' hidden={imgs.length > 0 ? false : true} >Latest</Typography>
                    {imgs.length > 0 ? <Cards imgs={imgs} /> : <h1>No Blogs </h1>}
                    <Typography style={{ margin: '0.5em' }} variant='h4' hidden={imgs1.length > 0 ? false : true}>Top rated</Typography>
                    <Cards imgs={imgs1} />
                    <Typography style={{ margin: '0.5em' }} variant='h4' hidden={imgs2.length > 0 ? false : true}>All</Typography>
                    <Cards imgs={imgs2} />
                </Grid>
            ) : (
                <Grid container direction='column'>
                    <Typography style={{ margin: '0.5em' }} variant='h4' hidden={this.state.imgs.length > 0 ? false : true}>Latest</Typography>
                    {this.state.imgs.length > 0 ? <Cards imgs={this.state.imgs} /> : <h1>No Blogs </h1>}
                    <Typography style={{ margin: '0.5em' }} variant='h4' hidden={this.state.imgs1.length > 0 ? false : true}>Top rated</Typography>
                    <Cards imgs={this.state.imgs1} />
                    <Typography style={{ margin: '0.5em' }} variant='h4' hidden={this.state.imgs2.length > 0 ? false : true}>All</Typography>
                    <Cards imgs={this.state.imgs2} />
                </Grid>
            )}

        </div>
    );
}
}

export default CategorySearch;