import React, { useState, useEffect } from 'react'
import './style.css';
import Testimonial from './Testimonial';
import Profile1 from '../../images/Testimonials/p1.svg';
import Profile2 from '../../images/Testimonials/p2.svg';
import Profile3 from '../../images/Testimonials/p3.svg';
import Profile4 from '../../images/Testimonials/p4.svg';
import TestimonialImg from './testimonial.svg';
import { Typography } from '@material-ui/core';



const Testimonials = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const data = [{
        name: 'Yash Dave CEO ,Founder Digital Cue Media  ',
        desc: 'A real pleasure to work with Prajol and his team, I have been working together for a few months now and it seems like years.The strength of Unisight is how they complete their tasks on time.',
        img: Profile1
    },
    {
        name: 'Lijo Thomas Founder Leighfisher Consultancy Sajan George ',
        desc: 'Great Team work, they read our mind and do far beyond our expectations.All the best and keep continuing the excellence.',
        img: Profile2
    },
    {
        name: 'Sajan George Founder Travancore Trust ',
        desc: 'Amazing work! On time delivery of website.You’ve read our minds and did far beyond our expectations.',
        img: Profile3
    },
    {
        name: 'Abhishek Sharma Marketing Lead x Vibe, Hike India Ex-Amazon,Ex-Castrol',
        desc: 'Unisight team is exceptional. I was impressed with their high degree of ownership. They really cared about solving the problem. The young team has domain expertise beyond their years. Overall it turned out to be a great decision to work with them and I highly recommend Unisight.  ',
        img: Profile4
    }]
    const updateLayout = () => {
        setWindowWidth(window.innerWidth);
    };
    // useEffect(()=>{

    //     // if(windowWidth!=window.innerWidth)
    //     return () => {
    //         window.removeEventListener('resize', updateLayout);
    //     };
    // });
    window.onresize = updateLayout;

    var x;
    if (windowWidth > 950)
        x = <img src={TestimonialImg} />;
    else {
        x = <div class="row">
            {data.map((testimonail, index) => {
                return <Testimonial key={index} data={testimonail} />
            })}
        </div>
    }
    return (
        <div class="testimonials">
            <div class="inner" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Typography style={{ fontWeight: '700', padding: '5px' }} variant='h5'>Testimonials</Typography>
                {x}
                {/* <button style={{ zIndex: '15' }} className="btnAllReview">View all reviews</button> */}
            </div>
        </div >
    )

}

export default Testimonials;


