import React, { Component, useEffect } from "react";
// import ReactDOM from "react-dom";
// import Carousel from "react-elastic-carousel";
// import MasterPieceCard from "../MasterPieceCard";
import "./style.css";
// import VisaToCyprusImg from '../../images/master_piece_img/visaToCyprus.svg';
// import VisaToAustralia from '../../images/master_piece_img/visaToAustralia.svg';
// import CharityWebsite from '../../images/master_piece_img/charityWebsite.svg';
// import B2bLife from '../../images/master_piece_img/b2bLife.svg';
// import VisaToCanada from '../../images/master_piece_img/visaToCanada.svg';
// import JobSearching from '../../images/master_piece_img/jobSearching.svg';
import { Container, Box, Button, Grid, Typography } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import Img from './img.svg';
import { useHistory } from "react-router";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

function Work() {
  let history=useHistory();
  // const data=[
  //   {
  //     title1:'Visa to Cyprus',
  //     img1:VisaToCyprusImg,
  //     title2:'Visa to Australia',
  //     img2:VisaToAustralia
  //   },
  //   {
  //     title1:'Charity Website',
  //     img1:CharityWebsite,
  //     title2:'B2B - Leighfisher',
  //     img2:B2bLife
  //   },
  //   {
  //     title1:'Visa to Canada',
  //     img1:VisaToCanada,
  //     title2:'Job searching - Tooly.com',
  //     img2:JobSearching
  //   },{
  //     title1:'Charity Website',
  //     img1:CharityWebsite,
  //     title2:'Visa to Australia',
  //     img2:VisaToAustralia
  //   }
  //   ]

  return (
    <>
      {/* <div className="WorkScroll">
        <Carousel breakPoints={breakPoints}>
          {data.map(card=>{
            return <MasterPieceCard data={card}/>;
          })}
        </Carousel>
      </div> */}
      <Container>
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }} direction='column' >
            <Box p={2}>
              <Typography variant='h4' style={{ fontWeight: '700' }}>Your Brand, Our Technology</Typography></Box>
            <Box p={2}><Typography variant='p'>We help you understand the need of Technology for your Brand, how it can grow your Business and solve your problems.
            So that you can focus on your product and we take care of building it from scratch!
            </Typography></Box>
             <Box p={2} style={{ width: '100%' }}> 
              <Button style={{ padding: '1em',background: '#4798EC', color: '#FFF', borderRadius: '25px' }} onClick={()=>{
                history.push('/work');
              }}>View All Projects <SendIcon style={{paddingLeft:'5px'}}/></Button>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box p={2}>
              <img style={{width:'100%'}}src={Img} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Work;
