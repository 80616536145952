import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '3em',
        fontFamily: 'Manrope'
    },
    addIcon: {
        color: '#0087CD'
    }
});

export default function Faq() {
    const classes = useStyles();
    const data = [
        {
            question: 'How long does it take to develop an average website?',
            answer: 'Website development is an innovative process we want to deliver the most latest design and technology while also accounting for your business goals. The time it takes to fully develop a website depends on lots of factors like scope of work, type of website, and the business requirements but usually, it takes around 1 to 2 weeks to develop a standard 5-page website. Your website might have a different development time check in with us and let’s find out more contact us now!\nStatic- 1 Week\nDynamic- 2-3 Weeks\nCMS/ Custom- >3 Weeks'
        },
        {
            question: 'What is the process of website development at Unisight Technology & Co. ?',
            answer: '1. Analyse client\'s business and focus on the problems that can be solved forthem through a website.\n2. Requirements gathering and features listing.\n3. Project Planning/Timeline \n4. UI/UX Designing(If required/necessary)\n5. Development(Frontend+Backend)\n6. Beta version(Prototype) release on staging server so that client can recommend changes.\n7. Testing all the features.\n8. Security checks\n9. Deployment(Publishing the website)'
        },
        {
            question: 'How do Unisight ensure the quality of the work?',
            answer: 'For designing-\nWe provide a beta version of the websites in around as early as possible so that the client can give their feedbacks way earlier. We try to understand the client\'s taste by doing multiple andfrequent interactions with the client. We take weekly feedbacks from the client.\nFor backend- \nWe do rigrous testing of the features that we create before deployment.'
        },
        {
            question: 'How much a website cost?',
            answer: 'Depends on the type of website. Contact us for a detailed quote.'
        },
        {
            question: 'What is CMS and how it can help me?',
            answer: 'CMS is a type of software that helps us as well as the client to create/modify content on their website after completion/deployment of the webiste.'
        },
        {
            question: 'What if I get technical issue with the website?',
            answer: 'We provide several maintanence plans for our clients on a monthly/yearly basis.We\'ll be there for any technical issues during or after deployment of the website.Our team will ensure that we handle the technical part so that the client can focus on expanding their business and not worry about the website.'
        }


    ]

    return (
        <div className={classes.root} >
            <Typography style={{ padding: '1em' }} variant='h4'>FAQ</Typography>

            {data.map(row => {
                return (
                    <>
                        <Accordion style={{ zIndex: '15', width: '90%', background: '#F6F6F6', marginTop: '10px',  maxWidth: '40em',    boxShadow: 'none',padding:'0 0.5em',      position: 'inherit',borderRadius: '5px'
 }}>
                        <AccordionSummary
                            expandIcon={<AddIcon className={classes.addIcon} />}
                            aria-label="Expand"
                            aria-controls="additional-actions2-content"
                            id="additional-actions2-header"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<p>&nbsp;&nbsp;&nbsp;</p>}
                                label={<h4>{row.question}</h4>}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1"
                                style={{ whiteSpace: 'pre-line' }} color="textSecondary">
                                {row.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    </>
    );
})}


        </div >
    );
}