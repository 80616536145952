import React,{useState} from 'react';
import './style.css';
import Logo from '../../images/logo.svg';
import HiIcons from './hamburger.svg';
import { NavLink } from 'react-router-dom';
import CloseIcon from './closeIcon.svg';
/**
* @author
* @function NavBar
**/

const NavBar = (props) => {
    const [navVisible,setNavVisible]=useState(false);

    var navCss='menu';
    if(navVisible){
        navCss='navOpen';
    }

    const navClose=()=>{
        setNavVisible(false);
    }
    return (
        <header class="header">
            <a href="/" class="logo"> 
                <img src={Logo}/><i style={{display:'flex',alignItems:'center'}}><h8 className="tagLine">“Unifying Minds and Technology”</h8></i>
            </a>          
            <label class="menu-icon" onClick={()=>{
                setNavVisible(!navVisible);
            }} for="menu-btn"><img src={HiIcons}/></label>
            
            <ul class={navCss}>
                <li><NavLink onClick={navClose} activeClassName="selected" to="/services">Services</NavLink></li>
                <li><NavLink onClick={navClose} activeClassName="selected" to="/blog">Blog</NavLink></li>
                <li><NavLink onClick={navClose} activeClassName="selected" to="/work">Work</NavLink></li>
                <li><NavLink onClick={navClose} activeClassName="selected" to="/about">About</NavLink></li>
                <li><NavLink onClick={navClose} activeClassName="selected" to="/contact">Contact us</NavLink></li>
                <li><img src={CloseIcon} className='closeIcon' onClick={navClose}/></li>
            </ul>
        </header>
    )

}

export default NavBar