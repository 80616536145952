import React from 'react'
import './style.css';
import Slide from "react-reveal/Slide";
import MainImage from '../../../images/main.svg';
/**
* @author
* @function ContentRight
**/

const ContentRight = (props) => {
  return(
    <div className="contentRightContainer">
      <Slide right>
        <img src={MainImage}/>
      </Slide>

    </div>
   )

 }

export default ContentRight;