import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/FooterContainer';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import Work from './pages/Work';
import Blog from './pages/Blog';
import ScrollToTop from './scrollToTop';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DetailedBlog from './pages/Blog/DetailedBlog';
import Carrer from './pages/Carrer';




function App() {
  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        'Manrope'
      ].join(','),
      
    },});
  return (
    <>
      <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop>
          <Navbar />

          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/contact' exact component={ContactUs} />
            <Route path='/about' exact component={AboutUs} />
            <Route path='/services' exact component={Services} />
            <Route path='/work' exact component={Work} />
            <Route path='/blog' exact component={Blog} />
            <Route path='/career' exact component={Carrer} />

            <Route path='/blog/get-details/:id?' exact component={DetailedBlog} />
          </Switch>
        </ScrollToTop>
      </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
