import { Container, Grid, Typography } from '@material-ui/core';
import React, { useEffect,useState } from 'react'
import './style.css';
import AchievementItem from './achievementItem';
import icon1 from '../../images/achivements_img/icon1.svg';
import icon2 from '../../images/achivements_img/icon2.svg';
import icon3 from '../../images/achivements_img/icon3.svg';
import icon4 from '../../images/achivements_img/icon4.svg';
import { execGetApi } from '../../lib/apiProvider';

/**
* @author
* @function Achievements
**/

const Achievements = (props) => {
    const [webHitCount, setWebHitCount] = useState('0');

    useEffect(() => {
        async function fetchData() {
            const request = execGetApi('getHits');
            return request;
        }
        const reqProjects = fetchData();
        reqProjects.then(res => setWebHitCount(res.data.hits));
    }, []);

    // console.log(webHitCount);
    var achievementData=[
        {
            title:'Website hits',
            titleCount:webHitCount,
            postTitleCount:'',
            icon:icon1
        },
        {
            title:'Websites deployed',
            titleCount:'20',
            postTitleCount:'+',
            icon:icon2
        }
        ,
        {
            title:'Countries served',
            titleCount:'15',
            postTitleCount:'+',
            icon:icon3
        },
        {
            title:'Lines of code',
            titleCount:'1',
            postTitleCount:'M+',
            icon:icon4
        }
    ];

    if(props.isStart){
        achievementData=[
            {
                title:'Website hits     ',
                titleCount:'0',
                postTitleCount:'',
                icon:icon1
            },
            {
                title:'Websites deployed',
                titleCount:'0',
                postTitleCount:'+',
                icon:icon2
            }
            ,
            {
                title:'Countries served',
                titleCount:'0',
                postTitleCount:'+',
                icon:icon3
            },
            {
                title:'Lines of code',
                titleCount:'0',
                postTitleCount:'M+',
                icon:icon4
            }
        ];
    }
    return (
        <Container style={{marginTop:'4em'}}>
            <Grid container justify='center'>
                {
                achievementData.map(achievement=>{
                    return <AchievementItem data={achievement}/>;
                })
                }
            </Grid>
        </Container>
    )

}

export default Achievements;