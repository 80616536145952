import { Grid } from '@material-ui/core';
import React from 'react'
import logoImg from '../../images/logo.svg';
import { NavLink } from 'react-router-dom';
import './style.css';
/**
* @author
* @function FooterEnd
**/

const FooterEnd = (props) => {
    return (
        <div style={{ background: '#fff', color: '#000', width: '100%' }}>
            <Grid container justify='space-between' alignItems='center' >
                <Grid className="innerContent"  item lg={3} md={4} sm={12} xs={12}>
                    <img style={{ padding: '1em' }} src={logoImg} />
                </Grid>
                <Grid className="innerContent"  item lg={6} md={12} sm={12} xs={12}>
                    <ul className="footer-links">
                        <li><NavLink className='NavFooterLink' activeClassName='footerLinkSelected' to="/services">Services</NavLink></li>
                        <li><NavLink className='NavFooterLink 'activeClassName='footerLinkSelected' to="/blog">Blog</NavLink></li>
                        <li><NavLink className='NavFooterLink' activeClassName='footerLinkSelected' to="/work">Work</NavLink></li>
                        <li><NavLink className='NavFooterLink' activeClassName='footerLinkSelected' to="/about">About</NavLink></li>
                        <li><NavLink className='NavFooterLink' activeClassName='footerLinkSelected' to="/career">Career</NavLink></li>
                        <li><NavLink className='NavFooterLink' activeClassName='footerLinkSelected' to="/contact">Contact us</NavLink></li>
                    </ul>
                </Grid>
                <Grid className="innerContent"  item lg={3} md={8} sm={12} xs={12}>
                    <p style={{ padding: '1em',fontSize:'0.8em' }} class="copyright-text">Copyright © 2020-2021 Unisight Technologies & CO.<br/>All rights are reserved.</p>
                </Grid>
            </Grid>
        </div>
    )

}

export default FooterEnd;