import { Box, Container, Grid } from '@material-ui/core';
import React from 'react'
import './style.css';
import ContactUs from '../ContactUs';
import HrefLogo from '../../images/hrefIcon.svg';
import FooterEnd from './footerEnd';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import CareerRegister from '../CareerRegister';
/*
* @author
* @function Footer
**/
//import 'bootstrap/dist/css/bootstrap.min.css';
const CareerFooter = (props) => {
  return (
    <>
      <footer class="site-footer">

        <Container>
          <Grid container justify='center' >
            <Grid item md={6} sm={12} justify='center'>
              <Box p={2}>
                <CareerRegister/>
              </Box>

            </Grid>
            
            {/* <Grid item xs={6} md={3}>
            <Box p={2}>

              <h6>Quick Links</h6>
              <ul class="footer-links">
                <li><a href="http://scanfcode.com/about/">About Us</a></li>
                <li><a href="http://scanfcode.com/contact/">Contact Us</a></li>
                <li><a href="http://scanfcode.com/contribute-at-scanfcode/">Contribute</a></li>
                <li><a href="http://scanfcode.com/privacy-policy/">Privacy Policy</a></li>
                <li><a href="http://scanfcode.com/sitemap/">Sitemap</a></li>
              </ul>
              </Box>
            </Grid> */}
          </Grid>
        </Container>
      </footer>
      <FooterEnd/>
    </>
  )

}

export default CareerFooter;