import React from 'react'
import ContentLeft from './ContentLeft'
import ContentRight from './ContentRight';
import './style.css';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';


/**
* @author
* @function ContentBlock
**/

const ContentBlock = (props) => {
    return(
        <div className="contentBlockContainer">
            {/* <ContentLeft {...props.left}/>
            <ContentRight {...props.left}/> */}
            <Container>
                <Grid container alignItems="center" justify="center">
                    <Grid item lg={6} style={{width:'100%'}}>
                        <ContentLeft/>
                        
                    </Grid>
                    <Grid item lg={6}>
                        <ContentRight/>
                    </Grid>
                </Grid>
            </Container>

                     
        </div>
    )

 }

export default ContentBlock;