import { Box, Container, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './style.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CommonHeader from '../../components/CommonHeader';
import Footer from '../../components/FooterContainer/commonFooter';
import Icon from './background.svg';
import TimerIcon from '@material-ui/icons/Timer';
import GoogleImg from "./background.svg";
import Img1 from './img1.svg';
import Img2 from './img2.svg';
import Img3 from './img3.svg';
import BgImg1 from "./bg.jpg";
import BgImg2 from "./bg2.jpg";
import BgImg3 from "./bg3.jpg";
import CategorySearch from './CategorySearch';
import { execGetApi } from '../../lib/apiProvider';
import Loading from './loading.gif';
const Blog = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const responsive1 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,

    }
  };
  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
  const ImgArray = [BgImg3,BgImg2,BgImg1];

  const CarouselItem = (props) => {
    return (

      <Grid item lg={12} md={12} sm={12} xs={12} style={{ position: "relative", height: '500px', borderRadius: '15px', padding: '0.1em', cursor: 'pointer' }} onClick={() => window.location.assign(`blog/get-details/${props.item.id}`)}>
        <div style={{ position: "relative", width: '100%', borderRadius: '15px', height: '500px', backgroundImage: `url(${ImgArray[props.index]})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>

          <Grid
            className="writeUpContainer"
            item
            lg={5} md={6} sm={12} xs={12}
            style={{
              position: "absolute",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              transform: " translateX(-50%)",
            }}
          >
            {/* <p style={{
            color: "#CDCDCD",
            textAlign: 'justify',
            display: 'flex',
            alignContent: 'center',
          }}>{props.item.tags} &nbsp;&nbsp;&nbsp;<TimerIcon />&nbsp;{props.item.time} min read</p> */}

            <Typography variant='body1' style={{ display: 'flex', marginTop: '10px', color: '#CDCDCD' }}>{props.item.tags.map(b => "#" + b)} </Typography>
            <Typography variant='body1' style={{ display: 'flex', marginTop: '10px', color: '#CDCDCD' }}><TimerIcon />&nbsp;{props.item.time} min read</Typography>
            <h3 style={{
              color: "white",

              display: 'flex',
              alignContent: 'center',
            }}>
              {props.item.title}
            </h3>
            <p max style={{
              color: "#D2D2D2",
              textAlign: 'justify',
              display: 'flex',
              alignContent: 'center',
            }}   >{truncate(props.item.desc, 110)}</p>
          </Grid>

        </div>

      </Grid>
    );
  }
  const [blogs, setAllBlogs] = useState([]);
  const [blogsFeatured, setBlogsFeatured] = useState([]);
  const [blogsFeatured2, setBlogsFeatured2] = useState([]);
  const [category, setCategory] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const request = execGetApi('getAllBlogs');
      return request;
    }
    async function fetchDataBlogsFeatured() {
      const request = execGetApi('getFeaturedBlogs');
      return request;
    }
    async function fetchDataBlogsFeatured2() {
      const request = execGetApi('getFeatured2Blogs');
      return request;
    }
    async function fetchDataCategory() {
      const request = execGetApi('getTags');
      return request;
    }
    const req = fetchData();
    req.then(res => setAllBlogs(res.data));
    const reqCategory = fetchDataCategory();
    reqCategory.then(res => setCategory(res.data.tags));
    const reqBlogFeatured = fetchDataBlogsFeatured();
    reqBlogFeatured.then(r => setBlogsFeatured(r.data));
    const reqBlogFeatured2 = fetchDataBlogsFeatured2();
    reqBlogFeatured2.then(r => {
      setBlogsFeatured2(r.data)
    });
  }, []);

  return (
    <div style={{
      display: 'flex',
      color: '#000',
      background: '#FFF',
      flexDirection: 'column',
      position: 'relative',
      overflowX: 'hidden',
      backgroundColor: '#FFFF'
    }} >

      <Container className="mainBlogContainer" style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }} >
        <Carousel
          ssr
          partialVisbile
          itemClass="image-item"
          responsive={responsive}
          infinite={true}
          showDots
          removeArrowOnDeviceType={["tablet", "mobile"]}
          autoPlaySpeed={5000}
          autoPlay
        >
          {blogsFeatured.map((b,index) => {
            return <CarouselItem item={b} index={index} />
          })}

        </Carousel>
      </Container>
      <Container style={{ marginTop: '5%' }} >
        <Carousel
          ssr
          partialVisbile
          itemClass="image-item"
          infinite={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          autoPlaySpeed={5000}
          autoPlay
          responsive={responsive1}>
          {
            blogsFeatured2.map((b, idx) => {
              return (
                <li key={idx} style={{ cursor: 'pointer' }} onClick={() => window.location.assign(`blog/get-details/${b.id}`)}>
                  <div key={idx} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', margin: '0px 0.3em' }}>
                    <img src={b.src} style={{ width: '100%', height: '250px', borderRadius: '10px', background: '#bababa' }} />
                    <div style={{ margin: '0 3px' }}>
                      <Typography variant='body1' style={{ display: 'flex', marginTop: '10px' }}>{b.tags.map(b => '#' + b)} </Typography>
                      <Typography variant='body1' style={{ display: 'flex', marginTop: '10px' }}><TimerIcon />&nbsp;{b.time} min read</Typography>
                      <Typography style={{ fontWeight: '700', fontSize: '18px', minHeight: '3em' }}>{b.title}</Typography>
                      <Typography className="hidden-mobile" variant="subtitle">{truncate(b.desc, 150)}</Typography>
                    </div>
                  </div>
                </li>

              );
            })
          }

        </Carousel>
      </Container>

      <Container style={{ marginTop: '5%' }} >
        {/* <ul>
          <li>Home</li>
          <li>Reviews</li>
          <li>Tech</li>
          <li>Developement</li>

        </ul> */}
        {blogs.length > 0 && category.length>0 ? <CategorySearch imgs={blogs} category={category.map(c => { return { name: c, status: false } })} />:<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><img src={Loading} /></div>}
     
      </Container>

      <Footer />


    </div >
  )

}

export default Blog;