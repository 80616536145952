import React, { useState } from 'react';
import './style.css';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import HrefLogo from '../../images/hrefIcon.svg';
import Tick from './tick.svg';
import FooterEnd from '../../components/FooterContainer/footerEnd';
import Subscribe from './suscribe.gif'
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { execApi } from '../../lib/apiProvider';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TickGif from '../../images/tickGIF.gif'
/**
* @author
* @function CommonFooter
**/
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width:'80%',
        maxWidth:'25em'
    },
}));




const CommonFooter = (props) => {
    const [subscribeEmail,setSubscribeEmail]=useState('');

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setX(<div class="lds-ring"><div></div><div></div><div></div><div></div></div>);
        setSubscribeEmail('');
    };

    const sendData = async () => {
        // console.log(subscribeEmail);
        const res = await execApi('/newsletter/', { email:subscribeEmail });
        return res;
    }
    
    const [x, setX] = useState(<div class="lds-ring"><div></div><div></div><div></div><div></div></div>);
    return (
        <>
        <div style={{
            marginTop: '5em'
        }}>

            <div style={{ background: '#135FAC', color: '#FFFF', zIndex: '14', position: 'relative' }}>
                <Container >
                    <Box p={2}>
                        <Grid container justify='space-between' alignItems='center' >
                            <Grid container lg={5} md={12} justify='space-between' >
                                <Grid item lg={5}>
                                    <Box style={{ padding: '1em 0 1em 0' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <h4><a style={{ textDecoration: 'none', color: '#FFF' }} href="mailto:info@unisighttechnologies.com?subject=Enquiry">Email</a></h4><EmailIcon style={{ marginLeft: '0.3em', cursor: 'pointer' }} />
                                        </div>
                                        <p><a style={{ textDecoration: 'none', color: '#FFF' }} href="mailto:info@unisighttechnologies.com?subject=Enquiry">info@unisighttechnologies.com</a></p>
                                    </Box>
                                </Grid>
                                <Grid item lg={5}>
                                    <Box style={{ padding: '1em 0 1em 0' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <h4><a style={{ textDecoration: 'none', color: '#FFF' }} href="tel:9799374580">Phone</a></h4><PhoneIcon style={{marginLeft: '0.3em',cursor:'pointer'}}/>
                                        </div>
                                        <p><a style={{ textDecoration: 'none', color: '#FFF' }} href="tel:9799374580">+91 9799-374-580</a></p>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container xs={12} md={12} lg={5} alignItems='center'>
                                <Grid item lg={5} md={12}>
                                    <Box style={{ padding: '1.8em 0 0 0' }}>
                                        <ul class="social-icons">
                                            <li><a style={{ background: 'E7E7E7' }} class="linkedin" href="https://www.linkedin.com/company/unisight-technologies1"><i class="fa fa-linkedin"></i></a></li>
                                            <li><a style={{ background: 'E7E7E7' }} class="facebook" href="https://www.facebook.com/UnisightTec/?ref=py_c">< i class="fa fa-facebook"></i></a></li>
                                            <li><a style={{ background: 'E7E7E7' }} class="insta" href="https://instagram.com/unisighttechnologies?igshid=yntq87yompzt"><i class="fa fa-instagram"></i></a></li>
                                        </ul>
                                    </Box>
                                </Grid>
                                <Grid item lg={7} md={12}>
                                    <Box style={{ padding: '1em 0 1em 0' }}>
                                        <h4>Subscribe to our Newsletter</h4>
                                        <form onSubmit={(e)=>{
                                                e.preventDefault();
                                                handleOpen();
                                                const res=sendData();
                                                res.then(r=>{
                                                    // console.log(r.data);
                                                    setX(<div className={classes.paper}>
                                                        <img style={{ width: '50%' }} src={r.data.status == "failed to insert" ? Subscribe:TickGif} />
                                                        <h2 style={{textAlign:'center'}}  id="transition-modal-title">{r.data.status == "failed to insert" ? "You have already been subscribed with us!":"Thanks for subscribing with us!"}</h2>
                                                        <p style={{textAlign:'center'}} id="transition-modal-description">Stay tuned for technical news and our new products/ services for you in future!</p>
                                                    </div>);
                                                })
                                            }} style={{ display: 'flex', flexDirection: 'row' }}>
                                            <input className="subscribeIp" value={subscribeEmail} required placeholder='Your email' type='email' onChange={(e)=>{
                                                setSubscribeEmail(e.target.value);
                                            }} required inputMode='email' />
                                            <input type='image' style={{ marginLeft: '8px', width: '2.7em',cursor:'pointer' }}  src={Tick} />
                                            
                                        </form>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <FooterEnd />
            </div>
            <div style={{ position: 'relative', zIndex: '0', bottom: '24em', height: '0' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#135FAC" fill-opacity="1" d="M0,128L120,138.7C240,149,480,171,720,170.7C960,171,1200,149,1320,138.7L1440,128L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
            </div>
        </div>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={open}>
            {x}
        </Fade>
    </Modal>
    </>

    )

}

export default CommonFooter;