import { Box, Container, Grid, Typography } from '@material-ui/core';
import React from 'react'
import './style.css';
import Footer from '../../components/FooterContainer/commonFooter';
import Icon1 from '../../images/about_us/icon1.svg';
import Icon2 from '../../images/about_us/icon2.svg';
import Icon3 from '../../images/about_us/icon3.svg';
import Icon4 from '../../images/about_us/icon4.svg';
import Icon5 from '../../images/about_us/icon5.svg';
import Icon6 from '../../images/about_us/icon6.svg';
import CommonHeader from '../../components/CommonHeader';
import Image1 from './img1.svg';
import Image2 from './img2.svg';
import Founder1 from './founder1.svg';
import Founder2 from './founder2.svg';
import TeamPic from './teamPic.svg';
/**
* @author
* @function AboutUs
**/

const AboutUs = (props) => {

    function FormRow(props) {
        return (
            <React.Fragment>
                <Grid container lg={5} md={11} xs={12} >
                    <Grid item xs={4} s={1} md={3} lg={3}>
                        <Box p={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <img src={props.icon1} alt="" />
                        </Box>
                    </Grid>
                    <Grid item xs={8} s={11} lg={7} md={9} direction='column' >
                        <h3 style={{ fontSize: "1.4em", margin: '0', marginTop: '15px', marginBottom: '3px', fontWeight: "bold" }}>
                            {props.title1}
                        </h3>
                        <span >
                            {props.desc1}
                        </span>
                    </Grid>
                </Grid>
                <Grid container lg={5} md={11} xs={12}>
                    <Grid item xs={4} lg={3} s={1} md={3}>
                        <Box p={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <img src={props.icon2} alt="" />
                        </Box>
                    </Grid>
                    <Grid item xs={8} lg={7} s={11} md={6} md={9} direction='column'>
                        <h3 style={{ fontSize: "1.4em", margin: '0', marginTop: '15px', marginBottom: '3px', fontWeight: "bold" }}>
                            {props.title2}
                        </h3>
                        <span >
                            {props.desc2}
                        </span>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
    function BuildFounders(props) {
        return (
            <Grid style={{ marginTop: '2em', textAlign: 'justify' }} container lg={12} md={10} sm={12} xs={12} spacing={3} >
                <Grid item style={{ display: 'flex', justifyContent: 'center' }} lg={4} md={5} sm={12} xs={12}>
                    <img src={props.img} />
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12}  >
                    <Typography style={{ fontWeight: 'bold', marginTop: '1.5em', textAlign: 'initial' }} variant='h5'>{props.title}</Typography>
                    <Typography style={{ whiteSpace: 'pre-line' }} variant="body1">
                        <Typography variant="p">{props.desc}</Typography>
                        <br />

                        <Typography variant="p"><br /> <i>{props.desc2}</i></Typography>

                    </Typography>
                    <Grid item lg={5} md={12}>
                        <Box style={{ padding: '1em 0 1em 0' }}>
                            <ul class="social-icon">
                                <li><a class="linkedin" style={{ background: '#0077B7', color: '#fff', cursor: 'pointer' }} href={props.linkdin}><i class="fa fa-linkedin"></i></a></li>
                                <li><a class="facebook" style={{ background: '#3B5998', color: '#fff', cursor: 'pointer' }} href={props.facebook}>< i class="fa fa-facebook"></i></a></li>
                                <li><a className="instagram" style={{ cursor: 'background' }} href={props.instagram}><i class="fa fa-instagram"></i></a></li>
                            </ul>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function FormRowWithImg(props) {
        return (
            <React.Fragment>
                <Container>
                    <Grid container direction={props.direction} spacing={1} justify='center'>
                        <Grid item xs={12} s={12} md={6} lg={5}>
                            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ width: '70%' }} src={props.img} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} s={12} md={6} lg={5}>
                            <Box p={2}>
                                <Typography style={{ fontWeight: '600', fontFamily: 'Manrope' }} variant='h5'>{props.title}</Typography>

                                <br />
                                <p>{props.desc1}</p>
                                <br />
                                <p>{props.desc2}</p>
                                <br />

                            </Box>

                        </Grid>

                    </Grid>
                </Container>
            </React.Fragment>
        );
    }


    return (
        <div style={{
            display: 'flex',
            color: '#000',
            background: '#FFF',
            flexDirection: 'column',
            position: 'relative',
            backgroundColor: '#FFFF'

        }} className="aboutUsContainer">
            <CommonHeader title="About us" />

            <Container alignItems='center' style={{ color: '#000', justifyContent: 'center', marginTop: '4em', padding: '1em 0em 1em 0em' }}>
                <Grid container justify="center" alignContent="center">
                    <Grid container justify='center' item xs={12} lg={12} md={12} style={{ textAlign: 'justify' }}>
                        <FormRowWithImg title='Who We Are? ' desc1='Unisight is not just a Web Development startup but we are a bunch of Hardworking young entrepreneurs who understand the business and the working. We are a Software Development Company founded and based in India in 2020 and since then till now we help our clients create their dream projects.' desc2='We at Unisight Technologies are a leading Web development Startup known for its high-quality innovative solutions. We specialize in Web Design, Web Development, Graphic Designing and a lot more…
' img={Image1} direction='row-reverse' />
                    </Grid>

                    <Grid style={{ marginTop: '3em' }} container justify='center' item xs={12} lg={12} md={12} style={{ textAlign: 'justify' }}>

                        <FormRowWithImg title='What we do?' desc1='We choose our developers after several technology tests to assure their knowledge and expertise. This is why we work with the best clients all around the world. We are cost-effective, we also work with agile methodologies and in the best time zones. 
Our rock is the System development ( Web and App) Project Management, Graphics and Content Creation. ' desc2='We work hard to go beyond the realms of the possible, to achieve any target with perfection in a given time.' img={Image2} />

                    </Grid>
                </Grid>
            </Container>
            <div className="curveOurCoreValues" style={{ position: 'relative', zIndex: '0', bottom: '4em', height: '82px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#F6F6F6" fill-opacity="1" d="M0,128L120,117.3C240,107,480,85,720,85.3C960,85,1200,107,1320,117.3L1440,128L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
            </div>
            <div className="aboutData">
                <h1 style={{ fontWeight: '600', padding: '1em' }}>Our Core Values</h1>
                <Box style={{ padding: '10px 16px 10px 10px' }}>
                    <div>
                        <Grid container justify="center" alignContent="center">
                            <Grid container justify='center' item xs={12} lg={12} md={12}>
                                <FormRow
                                    icon1={Icon1} icon2={Icon2}
                                    title1='Client Relationship'
                                    title2='Creative'
                                    desc1='We bring great ideas to life. We dive deep and work quickly to deliver the work. Our clients are family to us and for family best is important.'
                                    desc2='Bringing creativeness and high-quality work is what Unisight Technologies stands for. Our team always stay with the trend and keep the work as engaging as possible.' />
                            </Grid>
                            <Grid container justify='center' item xs={12} lg={12} md={12}>
                                <FormRow
                                    icon1={Icon3} icon2={Icon4}
                                    title1='On-Time submission'
                                    title2='Innovative'
                                    desc1='Our diverse and distributed team delivers empathy and understanding as well as world-class submission. Any time zones our services are on timely.'
                                    desc2="Imagine, Redefine and approach that's our mission at Unisight Technologies. Innovation and ideas are the core values. And we promise in making it easy and efficient." />
                            </Grid>
                            <Grid container justify='center' item xs={12} lg={12} md={12}>
                                <FormRow
                                    icon1={Icon5} icon2={Icon6}
                                    title1='Commitment'
                                    title2='Sharp Focus'
                                    desc1="Building hard work is a task and making it work for the clients is commitment. Unisight Technologies believes in commitment and dedication towards work always."
                                    desc2="Understanding the work and doing it is necessary. And our team never loses a second on focusing and building the task." />
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </div>

            <Container alignItems='center' style={{ color: '#000', justifyContent: 'center', marginTop: '4em', padding: '1em 0em 1em 0em' }}>
                <Grid container alignItems='center' justify='center' direction='row'>
                    <Grid item lg={8} md={10} sm={10} justify='center'>
                        <Box p={2}>
                            <Typography style={{ fontWeight: '600' }} variant='h5'>Why Unisight Technologies?</Typography><br />
                            <Typography variant='p'>We at Unisight Technologies offer the best in market service.
                            Either it's Responsive design or Light speed we are there to assist you.
                                <br /><br />
                                Responsive Websites are a must nowadays as 90% of internet traffic uses mobile phones. It is always recommended to have responsive website design and development because it users.
                                <br /><br />
                                We also always make sure when we develop any website it should be responsive and lite speed as it is a very important factor when it comes to SEO. It will help you to rank higher on search engines.
                                <br /><br />
                                As a website development company, we make sure to reach you in  24×7 phone or mail support for any emergency for any question and help with products email us at <a style={{ textDecoration: 'none', color: 'rgb(49,180,87)',fontWeight:'900' }} href="mailto:info@unisighttechnologies.com?subject=Enquiry">info@unisighttechnologies.com
                                </a>.
                            </Typography>
                        </Box>
                        <Box p={2}>
                            <Typography style={{ fontWeight: '600' }} variant='h5'>Mission and Vision</Typography><br />
                            <Typography variant='p'>Mission: We at Unisight Technologies work towards offering the best services to our clients. Working with dedication and providing on-time submission is our mission.
                                <br /><br />
                                Vision: Our prime vision is to helps business around the world with the technology aid so that the life of consumers become easier and faster.
We aim to make our own products using all the latest Technologies and contributing to the mankind.
                                <br /><br />

                            </Typography>
                        </Box>
                        <Box p={2}>
                            <Typography style={{ marginTop: '2em', fontWeight: '600' }} variant='h5'>Know our founder</Typography><br />
                            {/* <BuildFounders title='Prajol Sethi - The Visionary' desc='He is the main architect in building the fundamentals and guiding the vigorous thought process of the company workers to opinionate a firm solution to the clients. Somebody whose idea would always be to pertain solution to clients and sustain the best employee relations in the firm.' desc2='"Problems within, Problems with the work, Problems with the dilemmas, 
All exists but stability is the one to look after."' img={Founder1} instagram='https://instagram.com/prajolsethi207?igshid=xhvucp2qfntf' linkdin='https://www.linkedin.com/in/prajol-sethi-1b6933195' facebook='' /> */}
                            <BuildFounders title='Naitik Parmar - The Innovator' desc='He is the main epitome behind the ongoing techno-inducement in the company. Somebody who believes in the craft of merging the best technologies of the market works on the optimal solution to resolve the queries and in effective listening to bear hand all the client difficulties. ' desc2='"If you can think of the best there is no need of wasting time putting thousand thoughts forth."' linkdin='https://www.linkedin.com/in/naitik-parmar0909' instagram='https://instagram.com/naitik0909?igshid=1rufwop7t9tr6' facebook='https://www.facebook.com/naitik.parmar.507/' img={Founder2} />
                        </Box>
                        {/* <Box p={2}>
                            <Typography style={{ marginTop: '2em', fontWeight: '700', textAlign: 'center' }} variant='h4'>Our Team</Typography><br />
                            <img src={TeamPic} style={{ width: '100%' }} />
                        </Box> */}
                    </Grid>
                </Grid>
            </Container>


            <Footer />
        </div>
    )

}

export default AboutUs;