import { Box, Container, Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import CommonHeader from '../../components/CommonHeader';
import Faq from '../../components/Faq';
import Footer from '../../components/FooterContainer/commonFooter';
import Testimonials from '../../components/Testimonials';
import SampleImg from './sample.svg';
import SampleImg2 from './sample2.svg';
import Icon from './icons.svg';
import Icon2 from './icon2.svg';
import { execGetApi } from '../../lib/apiProvider';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@material-ui/core';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
/**
* @author
* @function Work
**/
const TopMakeMasterPiece = (props) => {
    return (
        <Grid style={{ marginTop: '4%' }} container item direction='column' justify='center'>
            <Grid item lg={10} md={10} sm={10} style={{ width: '100%' }}>
                <Box style={{ height: '20em' }}>
                    <img style={{ borderRadius: '10px', width: '100%', background: '#BABABA' }} src={props.img} />
                </Box>
            </Grid>
            <Grid item lg={10} md={10} sm={10}>
                <Typography style={{ fontWeight: '700', padding: '5px', color: '#0076B4' }} variant='h5'>{props.title}</Typography>
                <Box p={1} style={{ textAlign: 'justify', lineHeight: '1.7' }}>
                    <Typography variant='span'>{props.desc}</Typography>
                </Box>
            </Grid>

        </Grid>
    );
}

const MakeMasterPiece = (props) => {
    return (
        // <Grid style={{ marginTop: '4%' }} container lg={10} md={10} sm={12} direction='row' spacing={4} justify='space-between'>
        <>
            <Grid item lg={4} md={4} sm={6} style={{ alignItems: 'center', display: 'flex' }}>
                <img style={{ width: '100%', borderRadius: '10px',background: '#BABABA' }} src={props.img} />
            </Grid>
            <Grid item lg={6} md={8} sm={6}>
                <Typography style={{ fontWeight: '700', padding: '5px', color: '#0076B4' }} variant='h5'>{props.title}</Typography>
                <Box p={1} style={{ textAlign: 'justify', lineHeight: '1.7' }}>
                    <Typography variant='span'>{props.desc}</Typography>
                </Box>
            </Grid>
        </>

    );
}
const Work = (props) => {
    const [projects, setProjects] = useState([]);
    const [projectsFeatured, setProjectsFeatured] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const request = execGetApi('projects');
            return request;
        }
        async function fetchDataFeatured() {
            const request = execGetApi('projects_featured');
            return request;
        }


        setTimeout(async () => {
            const reqProjectsFeatured = execGetApi('projects_featured');

            reqProjectsFeatured.then(res => setProjectsFeatured(res.data));
        }, 3000);

        setTimeout(async () => {
            const reqProjects = execGetApi('projects');

            reqProjects.then(res => setProjects(res.data));
        }, 3000);
    }, []);
    // console.log(projects);
    // console.log(projectsFeatured);

    return (
        <div style={{
            display: 'flex',
            color: '#000',
            background: '#FFF',
            flexDirection: 'column',
            position: 'relative',
            overflowX: 'hidden',
            backgroundColor: '#FFFF'
        }} >
            <CommonHeader title="Work" />
            <Container style={{ marginTop: '5%' }} >
                <Grid container lg={8} md={8}  >
                    <Typography style={{ fontWeight: '700', padding: '5px' }} variant='h5'>Some of our masterpieces!</Typography>
                    <Box p={1}>
                        <Typography variant='span'>Unsight Technologies has been creating some amazing works over months and we believe in providing and crafting the best. Checkout out below some of our recent works and get to know our best.</Typography>
                    </Box>
                </Grid>
                <Grid container justify='center' spacing={5} direction='row' style={{ marginTop: '4%' }} alignItems='center'>

                    {/* <Grid item lg={6} md={8} sm={12} xs={12} >
                        <TopMakeMasterPiece img={SampleImg} desc='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam placerat dui massa, vitae mollis ex semper sit amet. Morbi ut lorem felis. Suspendisse molestie est eget molestie dictum. Duis fringilla fringilla dui, eu cursus orci condimentum vel. Suspendisse potenti. Curabitur vehicula ac sapien eu porta.' title='Lorem ipsum dolor sit amet, - Tooly' />
                    </Grid> */}
                    {projectsFeatured.length != 0 && projectsFeatured.map((project) => {

                        return (
                            <Grid item lg={6} md={8} sm={12} xs={12} key={project.id}>
                                { <TopMakeMasterPiece img={project.mockup} desc={project.short_description} title={project.title} />}
                            </Grid>
                        );
                    })}
                    {projectsFeatured.length == 0 &&
                        <Grid container>
                            <Grid item lg={6} md={8} sm={12} xs={12} >
                                <Grid item lg={4} md={4} sm={6} style={{ alignItems: 'center', display: 'flex' }}>
                                    <Skeleton height={300} width={500} />
                                </Grid>
                                <br />
                                <Grid item lg={6} md={8} sm={6}>
                                    <h5><Skeleton height={50} width={500} /></h5>
                                    <br />
                                    <span><Skeleton height={200} width={500} /></span>

                                </Grid>

                            </Grid>

                            <Grid item lg={6} md={8} sm={12} xs={12}>
                                <Grid item lg={4} md={4} sm={6} style={{ alignItems: 'center', display: 'flex' }}>
                                    <Skeleton height={300} width={500} />
                                </Grid>
                                <br />
                                <Grid item lg={6} md={8} sm={6}>
                                    <h5><Skeleton height={50} width={500} /></h5>
                                    <br />
                                    <span><Skeleton height={200} width={500} /></span>

                                </Grid>

                            </Grid>

                        </Grid>}



                </Grid>

                <Grid style={{ marginTop: '4%' }} container justify='center' spacing={4}>

                    {projects.length != 0 && projects.map((project) => {

                        return (

                            <MakeMasterPiece key={project.id} img={project.mockup} desc={project.short_description} title={project.title} style={{ padding: '10px' }} />

                        )
                    })}
                    {projects.length == 0 && [1,2,3,4].map((i) => {

                        return (
                            <>
                                <Grid item lg={4} md={4} sm={6} style={{ alignItems: 'center', display: 'flex' }}>
                                    <Skeleton height={230} width={400} />
                                </Grid>
                                <Grid item lg={6} md={8} sm={6}>
                                    <h5><Skeleton height={50} width={600}/></h5>
                                    <br/>
                                    <Box p={1} style={{ textAlign: 'justify', lineHeight: '1.7' }}>
                                        <span><Skeleton height={150} width={600} /></span>
                                    </Box>
                                </Grid>
                            </>

                        )
                    })}



                </Grid>
                <Grid style={{ marginTop: '10%' }} container alignItems='center' justify='center'>

                    <Carousel interval={3000} navButtonsAlwaysInvisible={true}>
                        <img style={{ width: '100%', maxWidth: '50em' }} src={Icon} />
                        <img style={{ width: '100%', maxWidth: '50em' }} src={Icon2} />
                    </Carousel>

                </Grid>
                <Grid style={{ marginTop: '10%' }} container justify='center'>
                    <Testimonials />

                </Grid>
                <Faq />

            </Container>
            <Footer />


        </div >
    )

}

export default Work;