import { Box, Container, Grid } from '@material-ui/core';
import React from 'react'
import './style.css';
import ContactUs from '../ContactUs';
import HrefLogo from '../../images/hrefIcon.svg';
import FooterEnd from './footerEnd';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
/*
* @author
* @function Footer
**/
//import 'bootstrap/dist/css/bootstrap.min.css';
const Footer = (props) => {
  return (
    <>
      <footer class="site-footer">

        <Container>
          <Grid container justify='center'>
            <Grid item md={6} sm={12} justify='center'>
              <Box p={2}>
                <ContactUs/>
              </Box>

            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={2}>
                <br />
                <br />
                {/* <div style={{display:'flex',flexDirection:'row'}}>
                <h6>Address</h6><img src={HrefLogo} style={{margin:'0 2px'}}/>
                </div>
                <p>Coming soon..</p>
                <br /> */}
                <div style={{display:'flex',flexDirection:'row'}}>
                <h6><a style={{textDecoration:'none',color:'#FFF'}} href="mailto:info@unisighttechnologies.com?subject=Enquiry">Email</a></h6><EmailIcon style={{margin:'0.2em 0.3em',cursor:'pointer'}} />
                </div>
                <p><a style={{textDecoration:'none',color:'#FFF'}} href="mailto:info@unisighttechnologies.com?subject=Enquiry">info@unisighttechnologies.com</a></p>
                <br />
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <h6><a style={{textDecoration:'none',color:'#FFF'}} href="tel:9799374580">Phone</a></h6><PhoneIcon style={{margin:'0.02em 0 0 0.1em ',cursor:'pointer'}}/>
                </div>
                <p><a style={{textDecoration:'none',color:'#FFF'}} href="tel:9799374580">+91 9799-374-580</a></p>
                <br />
                <ul class="social-icons">
                  <li><a class="linkedin" href="https://www.linkedin.com/company/unisight-technologies1"><i class="fa fa-linkedin"></i></a></li>
                  <li><a class="facebook" href="https://www.facebook.com/UnisightTec/?ref=py_c">< i class="fa fa-facebook"></i></a></li>
                  <li><a class="insta" href="https://instagram.com/unisighttechnologies?igshid=yntq87yompzt"><i class="fa fa-instagram"></i></a></li>
                </ul>
              </Box>
            </Grid>
            {/* <Grid item xs={6} md={3}>
            <Box p={2}>

              <h6>Quick Links</h6>
              <ul class="footer-links">
                <li><a href="http://scanfcode.com/about/">About Us</a></li>
                <li><a href="http://scanfcode.com/contact/">Contact Us</a></li>
                <li><a href="http://scanfcode.com/contribute-at-scanfcode/">Contribute</a></li>
                <li><a href="http://scanfcode.com/privacy-policy/">Privacy Policy</a></li>
                <li><a href="http://scanfcode.com/sitemap/">Sitemap</a></li>
              </ul>
              </Box>
            </Grid> */}
          </Grid>
        </Container>
            <FooterEnd/>
      </footer>
    </>
  )

}

export default Footer;