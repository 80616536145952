import React from 'react';
import Achievements from '../Achievements';
import './style.css';
import WorldImg from '../../images/world.svg';
import { Box, Grid } from '@material-ui/core';
import { useInView } from 'react-intersection-observer';
import LocationIcon from '../../images/location1.gif';
/**
* @author
* @function World
**/

const World = (props) => {

    const [ref, inView] = useInView({
        threshold: 0
    });
    var achievements = <Achievements isStart />
    if (inView) {
        achievements = <Achievements />
    }

    return (
        <div className="div-container">
            <section className='firstsection'>
                <div className="content">
                    <Grid container justify='center'>
                        {/* <Grid item lg={12}> */}

                        <div className="worldImageContainer">
                            <img className="worldimg" src={WorldImg} />
                            <div className="locationCanadaDiv">
                                <img className="locationCanada" src={LocationIcon} />
                                <span className="tooltiptext">CANADA</span>
                            </div>
                            <div className="locationAmericaDiv">
                                <img className="locationAmerica" src={LocationIcon} />
                                <span className="tooltiptext">AMERICA</span>
                            </div>
                            <div className="locationIrelandDiv">
                                <img className="locationIreland" src={LocationIcon} />
                                <span className="tooltiptext">IRELAND</span>
                            </div>
                            <div className="locationDenmarkDiv">
                                <img className="locationDenmark" src={LocationIcon} />
                                <span className="tooltiptext">DENMARK</span>
                            </div>
                            <div className="locationGermanyDiv">
                                <img className="locationGermany" src={LocationIcon} />
                                <span className="tooltiptext">GERMANY</span>
                            </div>
                            <div className="locationPortugalDiv">
                                <img className="locationPortugal" src={LocationIcon} />
                                <span className="tooltiptext">PORTUGAL</span>
                            </div>
                            <div className="locationGreeceDiv">
                                <img className="locationGreece" src={LocationIcon} />
                                <span className="tooltiptext">GREECE</span>
                            </div>
                            <div className="locationIndiaDiv">
                                <img className="locationIndia" src={LocationIcon} />
                                <span className="tooltiptext">INDIA</span>
                            </div>
                            <div className="locationAustraliaDiv">
                                <img className="locationAustralia" src={LocationIcon} />
                                <span className="tooltiptext">AUSTRALIA</span>
                            </div>
                            <div className="locationNewZealandDiv">
                                <img className="locationNewZealand" src={LocationIcon} />
                                <span className="tooltiptext">NEW ZEALAND</span>
                            </div>
                            <div className="locationCyprusDiv">
                                <img className="locationCyprus" src={LocationIcon} />
                                <span className="tooltiptext">CYPRUS</span>
                            </div>
                            <div className="locationSpainDiv">
                                <img className="locationSpain" src={LocationIcon} />
                                <span className="tooltiptext">SPAIN</span>
                            </div>
                            <div className="locationItalyDiv">
                                <img className="locationItaly" src={LocationIcon} />
                                <span className="tooltiptext">ITALY</span>
                            </div>
                            <div className="locationMaltaDiv">
                                <img className="locationMalta" src={LocationIcon} />
                                <span className="tooltiptext">MALTA</span>
                            </div>
                        </div>

                        {/* </Grid> */}
                    </Grid>
                    <Box style={{ marginBottom: '2em' }} ref={ref}>
                        {achievements}
                    </Box>
                </div>
            </section>


        </div>
    )

}

export default World