import { Box, Container, Grid } from '@material-ui/core';
import React from 'react';
import CountUp from 'react-countup';
/**
* @author
* @function AchievementItem
**/

const AchievementItem = (props) => {
 
        return (
        <Grid item lg={3} md={6} sm={12}>
        <Container >
            <Box p={1}>
                <Grid container justify='center' flexDirection='row'>

                    <Grid item lg={12}>
                        <div style={{display:'flex',flexDirection:'row'}}>
                        <Box p={1}>
                            <img src={props.data.icon} />
                        </Box>
                        <div style={{width:'120px'}}>
                        <Box p={1} style={{paddingBottom:'0px'}}><h2  style={{ fontWeight: '500' }}><CountUp duration={10} end={props.data.titleCount}/>{props.data.postTitleCount}</h2></Box>
                        <Box p={1} style={{paddingTop:'0px'}}><span >{props.data.title}</span></Box>
                        </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Container>
        </Grid>
    )

}

export default AchievementItem;