import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TempIcon from './pic1.svg';
import GrowthIcon from './growth1.png';
import OrgIcon from './organzation1.png';
import RewardIcon from './reward 1.png';
import './style.css';
import Footer from '../../components/FooterContainer/careerFooter';
import BgImg1 from "./on5.png";
import BgImg2 from "./till5.png";
import BgImg3 from "./with5.png";
import { execGetApi } from '../../lib/apiProvider';
import CareerImg from './new-careers-banner.svg';

const carouselTitle = ['Work with the Best!','Work on the Best!','Work till the Best!'];

const Carrer = (props) => {
    
    

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    function truncate(source, size) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }
    const ImgArray = [BgImg3, BgImg2, BgImg1];
    const CarouselItem = (props) => {
        return (

            <Grid item lg={12} md={12} sm={12} xs={12} style={{ position: "relative", height: '500px', borderRadius: '15px', padding: '0.1em'  }}>
                <div style={{ position: "relative", width: '100%', borderRadius: '15px', height: '500px', backgroundImage: `url(${ImgArray[props.index]})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>

                    <Grid
                        className="writeUpContainer"
                        item
                        lg={6} md={6} sm={12} xs={12}
                        style={{
                            position: "absolute",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            transform: " translateX(-50%)",
                        }}
                    >

                        <Typography variant="h4" style={{
                            color:'#fff',
                            marginBottom:'9em',
                            fontWeight:'700',
                            fontSize:'40px'
                            
                        }}>
                            {props.title}
                        </Typography>

                    </Grid>

                </div>

            </Grid>
        );
    }


    return (
        <div style={{
            display: 'flex',
            color: '#000',
            background: '#FFF',
            flexDirection: 'column',
            position: 'relative',
            overflowX: 'hidden',
            backgroundColor: '#FFFF'
        }} >
            <Container className="mainBlogContainer" style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }} >
                <Carousel
                    ssr
                    partialVisbile
                    itemClass="image-item"
                    responsive={responsive}
                    infinite={true}
                    showDots
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    autoPlaySpeed={5000}
                    autoPlay
                >
                    {carouselTitle.map((b, index) => {
                        return <CarouselItem title={b} index={index} />
                    })}

                </Carousel>
            </Container>
            <br/>
            <br/>
            <br/>
            <h1 style={{ textAlign: 'center', margin: '1em', fontWeight: '800', color: '#468CE5' }}>Our Work Motto</h1>
            <br/>
            <Container>
            <Grid container direction='row' justify='center' spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',alignItems:'center' }}>
                    <img src={OrgIcon} style={{width:'25%'}} />
                    <Box p={2} style={{textAlign:'center'}}>
                    <Typography variant="h6" style={{fontWeight:'600'}}>Organizational Structure</Typography>
                    <Typography variant="body2">Giving the best back and solving the complex solutions is our motto. We solve real-world solutions helping to bring change in businesses.</Typography>
                    </Box>
                    
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' ,alignItems:'center'}}>
                    <img src={GrowthIcon} style={{width:'25%'}} />
                    <Box p={2} style={{textAlign:'center'}}>
                    <Typography variant="h6" style={{fontWeight:'600'}}>Fastest Growing</Typography>
                    <Typography variant="body2">Join the community of the fastest growing tech community and be part of experienced developers who are well trained to guide and help you through the way.</Typography>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' ,alignItems:'center'}}>

                    <img src={RewardIcon} style={{width:'25%'}} />
                    <Box p={2} style={{textAlign:'center'}}>
                    <Typography variant="h6" style={{fontWeight:'600'}}>Dedication and rewards</Typography>
                    <Typography variant="body2">Innovation and skills brings the best in a developer, and we believe if one gives his best we will help him to bring out beta of him by guiding and rewarding.</Typography>
                    </Box>
                </Grid>
                
            </Grid>
            <br/>
            <br/>
            <br/>
            <br/>
            <Grid container justify='center' spacing={2} alignItems='center' >
            <Grid item lg={6}>
                <Box p={2}>
                <img src={CareerImg} style={{width:'100%'}} />
                </Box>
                
                </Grid>
                <Grid item lg={4}>
                <Box p={2}>
                <Typography variant="h4" style={{fontWeight:'600'}}>Work through passion!</Typography>
                <br/>
                <Typography variant="body2">Stretch your abilities. Improve your creativity. And impact projects that deliver modern, real-world solutions.
Whatever your passion, you'll find a role and jump-start your Unisight Technologies career. Follow us on <a style={{ textDecoration: 'none', color: 'rgb(10,102,194)',fontWeight:'900' }} href="https://www.linkedin.com/company/unisight-technologies1">Linkedin</a>  and <a style={{ textDecoration: 'none', color: 'rgb(228,69,164)',fontWeight:'900' }} href="https://instagram.com/unisighttechnologies?igshid=yntq87yompzt">Instagram</a>.</Typography>
                </Box>
                </Grid>
            </Grid>
            </Container>
            <Footer />
        </div>
    )

}

export default Carrer;