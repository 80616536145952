import React from 'react'
import './style.css';
import Footer from '../../components/FooterContainer/commonFooter';
import CommonHeader from '../../components/CommonHeader';
//Material UI
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Faq from '../../components/Faq/index';

//Tech Icons
import Pic1 from '../../images/ServicesImages/pic1.svg';
import Pic2 from '../../images/ServicesImages/pic2.svg';
import Pic3 from '../../images/ServicesImages/pic3.svg';
import Pic4 from '../../images/ServicesImages/pic4.svg';
import Python from '../../images/Technologies/python.svg';
import Html from '../../images/Technologies/html.svg';
import Django from '../../images/Technologies/django.svg';
import JS from '../../images/Technologies/js.svg';
import AWS from '../../images/Technologies/aws.svg';
import CSS from '../../images/Technologies/css.svg';
import Slack from '../../images/Technologies/slack.svg';
import GC from '../../images/Technologies/gc.svg';
import Heroku from '../../images/Technologies/css1.svg';
import CPanel from '../../images/Technologies/css2.svg';
import SG from '../../images/Technologies/sg.svg';
import Asana from '../../images/Technologies/asna.svg';
import Github from '../../images/Technologies/github.svg';
import ReactImg from '../../images/Technologies/react.svg';
import Bootstrap from '../../images/Technologies/bootstrap.svg';
import WordPress from '../../images/Technologies/wordpress.svg';
import Slide from "react-reveal/Slide";

/**
* @author
* @function Services
**/


const Services = (props) => {
    return (

        <div style={{
            display: 'flex',
            color: '#000',
            background: '#FFF',
            flexDirection: 'column',
            position: 'relative',
            overflowX: 'hidden',
            backgroundColor: '#FFFF'

        }} >
            <CommonHeader title="Services" />
            {/* <Container style={{ marginTop: '5em',padding:'0px' }}> */}
            <div style={{ textAlign: 'justify' }}>
                <Grid container justify="center" alignItems='center' direction="row-reverse" style={{ marginTop: '4em' }}>
                    <Grid item lg={4} md={4}>
                        <Slide right>
                            <img style={{ width: '100%' }} src={Pic1} />
                        </Slide>

                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Slide left>
                            <Box p={2}>
                                <Typography variant="h6" style={{ fontWeight: '700', marginBottom: '0.7em' }}>Website Design and Development</Typography>
                                <Typography variant="p" style={{ lineHeight: '1.7' }}>We give tweaked administrations beginning from web designing to development. We design websites to coordinate with your business prerequisites. We can take your business to the following level by making a Dynamic and Responsive Websites. We create solutions to innovate and improve your business and also make sure to bring the life to your ideas.
                            </Typography>
                            </Box>
                        </Slide>

                    </Grid>
                </Grid>
                <div style={{ position: 'relative' }}>
                    <div class="custom-shape-divider-top-1619302615">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                        </svg>
                    </div>
                    <Grid container justify="center" alignItems='center' style={{ background: '#F6F6F6', paddingTop: '1em', paddingBottom: '3.5em' }}>

                        <Grid item lg={4} md={4}>
                            <Slide left>
                                <img style={{ width: '100%' }} src={Pic2} />
                            </Slide>
                        </Grid>
                        <Grid item lg={4} md={4}>
                            <Slide right>
                                <Box p={2}>
                                    <Typography variant="h6" style={{ fontWeight: '700', marginBottom: '0.7em' }}>E-commerce Website</Typography>
                                    <Typography variant="p" style={{ lineHeight: '1.7' }}>We make great responsive E-trade websites with most recent advances and with highlights like Shopping Cart, Payment Gateway Integration, SSL.  We at Unisight Technologies are  expert creating and modifying the E-commerce websites and  we make it easy and simple for the audience searching you. We make sure to put the power for innovation.</Typography>
                                </Box>
                            </Slide>
                        </Grid>

                    </Grid>
                    <div class="custom-shape-divider-bottom-1619302524">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                        </svg>
                    </div>
                </div>
                <Grid container justify="center" alignItems='center' direction="row-reverse">

                    <Grid item lg={4} md={4}>
                        <Slide right>
                            <img style={{ width: '100%' }} src={Pic3} />
                        </Slide>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Slide left>
                            <Box p={2}>
                                <Typography variant="h6" style={{ fontWeight: '700', marginBottom: '0.7em' }}>UI/UX Designing</Typography>
                                <Typography variant="p" style={{ lineHeight: '1.7' }}>Getting on the perfection with every thing in a website is necessary and we at Unisight Technologies make sure that we work on it. From discovery and research through pixel-perfect handoff and design system creation and refinement, your users are our priority. You bring the ideas, we'll bring the expertise.
                        </Typography>
                            </Box>
                        </Slide>
                    </Grid>
                </Grid>
                <div style={{ position: 'relative' }}>
                    <div class="custom-shape-divider-top-1619302615">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                        </svg>
                    </div>
                    <Grid container justify="center" alignItems='center' style={{ background: '#F6F6F6', paddingTop: '1em', paddingBottom: '3.5em' }}>

                        <Grid item lg={4} md={4}>
                            <Slide left>
                                <img style={{ width: '100%' }} src={Pic4} />
                            </Slide>
                        </Grid>
                        <Grid item lg={4} md={4}>
                            <Slide right>

                                <Box p={2}>
                                    <Typography variant="h6" style={{ fontWeight: '700', marginBottom: '0.7em' }}>Job/Aggregator Portal</Typography>
                                    <Typography variant="p" style={{ lineHeight: '1.7' }}>
                                        We have been successful in developing the best systems in this segment keeping both the types of user in mind.In todays time the vision is to make all services online we have been using various AI and Machine Learning algorithms so that all the documention between the demand and supply may take place virtually by going paperless.                        </Typography>
                                </Box>
                            </Slide>
                        </Grid>
                    </Grid>

                    <div class="custom-shape-divider-bottom-1619302524">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <Slide down>

                <Box style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
                    <Typography variant='h5' style={{ fontWeight: '700' }}>Technologies</Typography>
                </Box>
                <Grid container justify="center" alignItems='center' justify="center" style={{ textAlign: 'center' }} direction="column">
                    <Grid item container justify="center" spacing={3} lg={10} xs={10}>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={Python}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>Python</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={Django}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>Django</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={Html}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>HTML</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={CSS}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>CSS 3</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={JS}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>JS</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={AWS}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>AWS</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={WordPress}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>WordPress</Typography>
                        </Grid>

                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={Bootstrap}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>Bootstrap</Typography>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Grid item container justify="center" spacing={3} lg={10} xs={10}>


                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={GC}></img>
                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h7" style={{ fontWeight: '600' }}>G-Cloud</Typography>
                                {/* <Typography variant="h7" style={{ fontWeight: '600' }}>cloud</Typography> */}
                            </Box>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={Heroku}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>Heroku</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={CPanel}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>CPanel</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={Asana}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>Asana</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={SG}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>SendGrid</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={Slack}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>Slack</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={Github}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>Git</Typography>
                        </Grid>

                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="zoom-effect-container" item lg={1}>
                            <img src={ReactImg}></img>
                            <Typography variant="h7" style={{ fontWeight: '600' }}>React</Typography>
                        </Grid>


                    </Grid>
                </Grid>
            </Slide>
            {/* </Container> */}

            <Faq />
            <Footer />
        </div>


    )

}

export default Services